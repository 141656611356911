import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Head from 'next/head';
import LazyHydrate from 'react-lazy-hydration';
import { Page, Content, PageWithNav } from '../theme/page';

import SCOPE_TYPES from '../utils/sentryScopeTypes';
import { generateMeta, getMetaImage } from '../utils/meta';
import { formatCmsResponse } from '../utils/cms';
import getModuleTemplates from '../utils/cms/getModuleTemplates';
import encodedUrlFilterQuery from '../utils/cms/encodedUrlFilterQuery';
import isModuleBackgroundWhite from '../utils/isModuleBackgroundWhite';
import hasHr from '../utils/hasHr';

import InnerPageNavigation from '../tissues/inner-page-navigation';

import { getPageParams } from './api';

const isDoubleBanner = (modulename) => modulename === 'double_banner';
const isAccordion = (modulename) => modulename === 'accordion';

const Cms = (props) => {
  const router = useRouter();
  const templates = getModuleTemplates(props.modules);

  return (
    <>
      <Head>
        {generateMeta({
          key: 'cms',
          title: props.page.meta_data?.title,
          description: props.page.meta_data?.description,
          og: {
            description: props.page.meta_data?.description,
            image: getMetaImage(props),
            title: props.page.meta_data?.title,
            url: `${process.env.NEXT_PUBLIC_CLIENT_HOST}${router.asPath}`
          },
          twitter: {
            description: props.page.meta_data?.description,
            image: getMetaImage(props),
            title: props.page.meta_data?.title
          }
        })}

        <link key="canonical" rel="canonical" href={process.env.NEXT_PUBLIC_CLIENT_HOST} />

        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
              {
                "@context": "https://schema.org",
                "@type": "Corporation",
                "name": "Maisonette Inc.",
                "alternateName": "Maisonette",
                "url": "https://www.maisonette.com/",
                "logo": "${getMetaImage()}",
                "sameAs": [
                  "https://www.facebook.com/maisonetteworld",
                  "https://twitter.com/maisonetteworld",
                  "https://www.instagram.com/maisonetteworld/",
                  "https://www.youtube.com/c/maisonetteworld",
                  "https://www.linkedin.com/company/maisonette-inc./",
                  "https://www.pinterest.com/maisonetteworld/",
                  "https://www.maisonette.com/"
                ]
              }
            `
          }}
        />
      </Head>
      {props?.page?.inner_page_navigation ? (
        // TODO: can this be removed or refactored? The Homepage won't ever have
        // inner_page_navigation
        <Page background={props.page.background} id="maincontent">
          <PageWithNav>
            <InnerPageNavigation activePageName={props.page.url} />
            {templates.map((template, index) => {
              const { Template, title, position, data } = template;
              return data && Object.keys(data).length > 0 ? (
                <Content
                  className="cms-content"
                  template={template}
                  isCMS
                  hasHr={hasHr(data)}
                  noPadding={title === 'markup'}
                  key={`${title}-${position}`}
                  isWhite={isModuleBackgroundWhite(title)}
                  isDoubleBanner={isDoubleBanner(title)}
                  isAccordion={isAccordion(title)}
                  position={position}>
                  <LazyHydrate whenVisible>
                    <Template data={data} disableLazyload={index < 2} homepage />
                  </LazyHydrate>
                </Content>
              ) : null;
            })}
          </PageWithNav>
        </Page>
      ) : (
        <Page background={props.page.background} id="maincontent">
          {templates.map((template, index) => {
            const { Template, title, position, data } = template;
            return data && Object.keys(data).length > 0 ? (
              <Content
                className="cms-content"
                template={template}
                isCMS
                hasHr={hasHr(data)}
                noPadding={title === 'markup'}
                key={`${title}-${position}`}
                isWhite={isModuleBackgroundWhite(title)}
                isDoubleBanner={isDoubleBanner(title)}
                isAccordion={isAccordion(title)}
                position={position}>
                <LazyHydrate whenVisible>
                  <Template data={data} disableLazyload={index < 2} homepage />
                </LazyHydrate>
              </Content>
            ) : null;
          })}
        </Page>
      )}
    </>
  );
};

export async function getServerSideProps() {
  let props = {};

  const pages = await getPageParams({
    base: process.env.CMS_HOST,
    uri: '/api/pages',
    queryParams: { [encodedUrlFilterQuery]: '/index', onlyLatestContent: true },
    scopes: [SCOPE_TYPES.SERVICES.STRAPI]
  });

  // TODO: do we need better error messaging from Strapi?
  const pageData = formatCmsResponse(pages);

  if (pageData.code === 'error') {
    // product redirects handled in pages/[cms]
    return {
      notFound: true
    };
  }

  if (pageData.code === 'ok') {
    props = { ...props, page: pageData.data };
    const activeContents = pageData.data.contents.filter((content) => content.publishedAt);
    const currentDateTime = Date.now();
    const pagePublishDateTime = Date.parse(pageData.data.publish_date);

    if (!pageData.data.publishedAt || activeContents.length === 0 || pagePublishDateTime > currentDateTime) {
      return {
        notFound: true
      };
    }

    const contentData = formatCmsResponse([activeContents[0]]);
    const contentPublishDateTime = Date.parse(contentData.data.publish_date);

    if (contentData.code === 'error') {
      return {
        notFound: true
      };
    }

    if (contentData.code === 'ok' && contentPublishDateTime < currentDateTime) {
      const { modules } = contentData.data;
      if (modules?.length > 0) props = { ...props, modules };
    }
  }

  return { props };
}

Cms.defaultProps = {
  modules: [],
  page: {}
};

Cms.propTypes = {
  page: PropTypes.object,
  modules: PropTypes.array
};

export default Cms;
